.App {
  max-width: 1000px;
  margin: auto;
  padding: 16px;
  min-height: 100vh;

  .Grid {
    display: grid;
    margin: 0 20px;

    @media (min-width: 800px) {
      grid-template-columns: 50% 50%;
      grid-template-rows: auto auto auto;
      gap: 20px;

      .Meta {
        grid-column: 1 / 1;
        grid-row: 2 / 2;
      }

      .Form {
        grid-column: 2 / 2;
        grid-row: 1 / span 3;
      }
    }
  }
}
